import type {ThrownResponse} from '@remix-run/react';
import {
  Links,
  LiveReload,
  Outlet,
  Scripts,
  ScrollRestoration,
  useCatch,
  useSearchParams,
} from '@remix-run/react';
import type {LinksFunction} from '@remix-run/cloudflare';
import tailwindStyleSheet from '~/styles/tailwind.css';
import componentStyle from '@tendy/react/dist/index.css';
import {useEffect} from 'react';
import {themeChange} from 'theme-change';
import {Modals} from '@tendy/react';

export const links: LinksFunction = () => {
  return [
    {
      rel: 'stylesheet',
      href: componentStyle,
    },
    {
      rel: 'stylesheet',
      href: tailwindStyleSheet,
    },
    {rel: 'preconnect', href: 'https://fonts.googleapis.com'},
    {rel: 'preconnect', href: 'https://fonts.gstatic.com'},
    {
      rel: 'stylesheet',
      href: 'https://fonts.googleapis.com/css2?family=Exo+2:ital@1&display=swap',
    },
  ];
};

export default function App() {
  return <RootApp />;
}

function RootApp() {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    themeChange(false);
  });
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <title>💎 Tendy</title>
        <Links />
      </head>
      <body>
        <Outlet />
        <ScrollRestoration />
        <Scripts />
        <LiveReload />
        <Modals searchParams={searchParams} />
      </body>
    </html>
  );
}

export function ErrorBoundary({error}: {error: Error}) {
  console.log('error');
  return (
    <div>
      <h1>Error</h1>
      <p>{error.message}</p>
      <p>The stack trace is:</p>
      <pre>{error.stack}</pre>
    </div>
  );
}

export function CatchBoundary() {
  console.log('asdfasdf');
  const caught = useCatch<DefaultCatchData>();

  return <DefaultCatchBoundary caught={caught} />;
}
export interface DefaultCatchData extends ThrownResponse {
  modal: string;
}

export function DefaultCatchBoundary({caught}: {caught: DefaultCatchData}) {
  return <RootApp />;
}
